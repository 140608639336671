import { createStore } from 'vuex'

export default createStore({
  state: {
    id:4,
    todos:[
      {id:1, text:'buy a car', done: false},
      {id:2, text:'play game', done: true},
      {id:3, text:'study', done: false},
    ],
    noCompletedTodos:[
      {id:1, text:'buy a car', done: false},
      {id:3, text:'study', done: false},
  ],
    completedTodos:[
      {id:2, text:'play game', done: true},
    ]
  },
  getters: {
    noCompletedTodos(state){
      return state.todos.filter(todo => !todo.done)
    }
  },
  mutations: {
    addTodo(state, payload){
      state.noCompletedTodos.unshift({id:state.id++, text:payload, done:false})
    },
    changeStatusTodos(state,payload){
      let index=state.todos.findIndex(todo => todo.id === payload)
      let todo=state.todos[index]
      todo.done=!todo.done
    },
    removeTodo(state,payload){
      if(state.completedTodos.findIndex(todo => todo.id === payload) !== -1){
        let index=state.completedTodos.findIndex(todo => todo.id === payload)
        state.completedTodos.splice(index,1)
      }else{
        let index=state.noCompletedTodos.findIndex(todo => todo.id === payload)
        state.noCompletedTodos.splice(index,1)
      }
    },
    actualizeCompletedTodos(state,payload){
      state.completedTodos=payload
    },
    actualizeNoCompletedTodos(state,payload){
      state.noCompletedTodos=payload
    },
    actualizeTodos(state,){
      state.todos=state.noCompletedTodos.concat(state.completedTodos);
    },
    clearCompleted(state){
      state.completedTodos=[]
    }
    

  },
  actions: {
    setAll({commit}){
      let completedTodos=this.state.todos.filter(todo => todo.done)
      let noCompletedTodos=this.state.todos.filter(todo => !todo.done)
      commit('actualizeCompletedTodos',completedTodos)
      commit('actualizeNoCompletedTodos',noCompletedTodos)
    },
    setConcat({commit}){
      commit('actualizeTodos')
    },
    addTodoAction({commit},payload){
      commit('addTodo',payload);
      commit('actualizeTodos')
    },
    changeStatusTodosAction({commit,dispatch},payload){
      commit('changeStatusTodos',payload);
      dispatch('setAll')
    },
    clearCompletedAction({commit,dispatch}){
      commit('clearCompleted');
      dispatch('setConcat')
    },
    removeTodoAction({commit,dispatch},payload){
      commit('removeTodo',payload);
      dispatch('setConcat')
    }

  },
  modules: {
  }
})
