<template>
<footer id="main_footer">
  <h1>TODO</h1>
  <div>
    <svg class="img_theme" @click="changeTheme" v-if="theme=='dark'" xmlns="http://www.w3.org/2000/svg" width="26" height="26"><path fill="#FFF" fill-rule="evenodd" d="M13 21a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-5.657-2.343a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414-1.414l2.12-2.121a1 1 0 011.415 0zm12.728 0l2.121 2.121a1 1 0 01-1.414 1.414l-2.121-2.12a1 1 0 011.414-1.415zM13 8a5 5 0 110 10 5 5 0 010-10zm12 4a1 1 0 110 2h-3a1 1 0 110-2h3zM4 12a1 1 0 110 2H1a1 1 0 110-2h3zm18.192-8.192a1 1 0 010 1.414l-2.12 2.121a1 1 0 01-1.415-1.414l2.121-2.121a1 1 0 011.414 0zm-16.97 0l2.121 2.12A1 1 0 015.93 7.344L3.808 5.222a1 1 0 011.414-1.414zM13 0a1 1 0 011 1v3a1 1 0 11-2 0V1a1 1 0 011-1z"/></svg>
    <svg class="img_theme" @click="changeTheme" v-else xmlns="http://www.w3.org/2000/svg" width="26" height="26"><path fill="#FFF" fill-rule="evenodd" d="M13 0c.81 0 1.603.074 2.373.216C10.593 1.199 7 5.43 7 10.5 7 16.299 11.701 21 17.5 21c2.996 0 5.7-1.255 7.613-3.268C23.22 22.572 18.51 26 13 26 5.82 26 0 20.18 0 13S5.82 0 13 0z"/></svg>
  </div>
</footer>
<section id="section_drag">
  <div class="container">
    <div class="rond"></div>
    <input autocomplete="off" id="input_todo" type="text" ref="input_todo" placeholder="Create a new todo..." @keypress.enter="addTodo">
  </div>
  <div  id="vue_drag">
    <dragVue :filter="filter"></dragVue>
    <div id="option">
      <p>{{todoNumber}} item<span v-if="todoNumber>1">s</span> left</p>
      <p @click="clearCompleted" id="clear_all">Clear Completed</p>
    </div>
  </div>
  <div id="filter">
    <p :class="`${filter=='all'?'selected':''} option_filter`" @click="filter='all'">All</p>
    <p :class="`${filter=='active'?'selected':''} option_filter`" @click="filter='active'">Active</p>
    <p :class="`${filter=='completed'?'selected':''} option_filter`" @click="filter='completed'">Completed</p>
  </div>
 <p style="textAlign:center;color:hsl(233, 11%, 84%)">Drag and drop to reorder list</p>
</section>
</template>

<script>
import dragVue from "@/components/dragVue.vue";
export default {
  name: "simpLe",
  display: "Simple",
  components: {
    dragVue
  },
  data() {
    return {
     theme: 'dark',
     filter: 'all',
     width:null
    };
  },
  mounted(){
    addEventListener('resize',()=>{
      this.width = window.innerWidth
    })
  },
  computed: {
    todoNumber(){
      return this.$store.getters.noCompletedTodos.length
    }
  },
  watch:{
    width(newValue,oldValue){
      if(newValue<910 && oldValue>=910)this.changeTheme()
      if(newValue>=910 && oldValue<910)this.changeTheme()
    }
  },
  methods: {
    addTodo(){
      this.$store.dispatch('addTodoAction', this.$refs.input_todo.value)
      this.$refs.input_todo.value = ''
      this.filter = 'all'
    },
    clearCompleted(){
      console.log('clear')
      this.$store.dispatch('clearCompletedAction')
    },
    changeTheme(){
      let size=null
      if(this.width<910)size='mobile'
      else size='desktop'

      if(this.theme == 'dark'){
        document.querySelector('#app').style.backgroundImage="url('" + require(`@/assets/bg-${size}-light.jpg`) + "')"
        document.querySelector('.container').classList.add('light');
        document.querySelector('#app').classList.add('light');
        document.querySelector('#vue_drag').classList.add('light');
        document.querySelector('#filter').classList.add("light");
        this.theme = 'light'
      }else{
        this.theme = 'dark'
        document.querySelector('#app').style.backgroundImage="url('" + require(`@/assets/bg-${size}-dark.jpg`) + "')"
        document.querySelector('.container').classList.remove('light');
        document.querySelector('#vue_drag').classList.remove('light');
        document.querySelector('#filter').classList.remove('light');
        document.querySelector('#app').classList.remove('light');
      }
    }
  }
};
</script>
<style>
#app{
  min-height: 100vh;
  background-color: hsl(235, 21%, 11%);
  background-image: url(./assets/bg-mobile-dark.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  min-width: 330px;
}
#main_footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 10px;
}
.container{
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  height: fit-content;
  min-height: 50px;
  color: #FFF;
  background-color: hsl(235, 24%, 19%);
  padding: 0 15px;
  gap: 20px;
  border-radius: 5px;
}
.light{
  background-color: #FFF !important;
  color: hsl(235, 24%, 19%) !important;
}

.rond{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid hsl(234, 11%, 52%);
  display: flex;
  justify-content: center;
  align-items: center;
}
#input_todo{
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: hsl(234, 11%, 52%);
  font-size: 14px;
  outline: none;
}
#section_drag{
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
#vue_drag{
  display: flex;
  flex-direction: column;
  background-color:hsl(235, 24%, 19%) ;
  border-radius: 5px;
  padding: 5px 0;
  color: hsl(233, 11%, 84%);
  box-shadow: 0px 20px 20px -20px hsl(235, 21%, 11%);
}
#option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  color: hsl(234, 11%, 52%);
  font-size: 14px;
  height: 50px;
}
#filter{
  display: flex;
  justify-content: center;
  background-color:hsl(235, 24%, 19%) ;
  border-radius: 5px;
  align-items: center;
  color: hsl(234, 11%, 52%);
  font-size: 14px;
  height: 50px;
  gap: 20px;
  padding: 0 15px;
  box-shadow: 0px 20px 20px -20px hsl(235, 21%, 11%);
}
.selected{
  color: hsl(220, 98%, 61%);
  font-weight: 700;
  cursor: default !important;
}
.selected:hover{
  color: hsl(220, 98%, 61%) !important;

}
.option_filter{
  cursor: pointer;
}
.option_filter:hover{
  color: #fff;
}
#clear_all{
  cursor: pointer;
}
#clear_all:hover{
  color: #fff;
}
.img_theme{

  cursor: pointer;
}
@media(min-width: 900px){
  #app{
    background-image: url(./assets/bg-desktop-dark.jpg);
    align-items: center;
    background-size: 100% auto;
  }

  #main_footer{
    max-width: 1000px;
    min-width: 600px;
  }

  #section_drag{
    max-width: 1000px;
    min-width: 600px;
    
  }
}
</style>