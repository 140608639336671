<template>
      <draggable 
      :list="todos" 
      :disabled="!enabled" 
      item-key="name" 
      class="list-group" 
      ghost-class="ghost"
      handle=".handle"
      :move="console.log('move')" 
      @start="dragging = true" 
      @end="setData" 
      >

        <template #item="{ element }">
            <div class="list-group-item">
                <div :class="`rond ${element.done?'validTodo':''}`" @click.prevent.stop="validTodo(element)" >
                  <svg v-if="element.done" xmlns="http://www.w3.org/2000/svg" width="11" height="9"><path fill="none" stroke="#FFF" stroke-width="2" d="M1 4.304L3.696 7l6-6"/></svg>
              </div>
              
              <div id="text" :class="`handle ${element.done?'validText':''}`" ><p  style="word-break:break-all" v-html="element.text"></p></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" @click="removeTodo(element)"><path fill="#494C6B" fill-rule="evenodd" d="M16.97 0l.708.707L9.546 8.84l8.132 8.132-.707.707-8.132-8.132-8.132 8.132L0 16.97l8.132-8.132L0 .707.707 0 8.84 8.132 16.971 0z"/></svg>
            </div>
            
        </template>
      </draggable>
  
</template>
  
<script>
import draggable from "vuedraggable";

export default {
  name: "simpLe",
  display: "Simple",
  order: 0,
  components: {
    draggable
  },
  props: {
    filter: {
      type: String,
      default: "all"
    },
  },
  data() {
    return {
      enabled: true,
      list: this.$store.state.todos,
      dragging: false
    };
  },
  watch:{

  },
  computed: {

    todos() {
        if(this.filter === "all") return this.$store.state.todos;
        if(this.filter === "active") return this.$store.state.noCompletedTodos;
        else return this.$store.state.completedTodos;
      },

    },
  methods: {
    validTodo(element) {
      this.$store.dispatch("changeStatusTodosAction",element.id);
    },
    removeTodo(payload) {
      this.$store.dispatch("removeTodoAction",payload.id);
    },
    setData(){
      this.dragging=false;
      if(this.filter === "all") this.$store.dispatch("setAll");
      else this.$store.dispatch("setConcat");
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.list-group-item{
  height: fit-content;
  min-height: 50px;
  display: grid;
  grid-template-columns: 20px 6fr 18px;
  align-items: center;
  padding: 0 15px;
  gap: 20px;
  border-bottom: 1px solid #494C6B;

}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  color: #c8ebfb
}

.not-draggable {
  cursor: no-drop;
}
#rond_container{
  display: flex;
  justify-content: center;
  width: 30px;
  height:30px;
  align-items: center;
}
.slide-move,
.slide-leave-active {
  transition: transform 1s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.validTodo{
  background-image: linear-gradient(135deg, hsl(192, 100%, 67%), hsl(280, 87%, 65%));

}
.validText{
  text-decoration: line-through;
  color: hsl(233, 14%, 35%) !important;
}
@media(min-width: 600px){

}
/* Styles for draggable and item elements */
</style>